import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
const Iceemprep = () => {
 
  const auth = useAuthUser();  


 
  
  useEffect( () => {
    

    
    
 
    alloutcome();
 
    
  
  }, []); 
  




  

  const [outcome, setOutcome] = useState([]);
  
  const [data, setData] = useState([]);
const [loading, setLoading] = useState(false);
  const alloutcome = async () => {
    
    setLoading(true);
    try {
     
    
      const username =  auth().username;
    const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/iceemp.php?key=`+key+`&username=`+username)
      .then(res => {
      
      
        if(res.data.success === true){
          setOutcome([]);
        setOutcome(res.data.productlist.productdata);
        setData([]);
        setData(res.data.productlist.productdata);

        }
        setLoading(false);
          window.location.href='#report'
      })
   
    } catch (error) { throw error;}    
   
  }
  
  

  
  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 

  
  
  const filt = () =>{
   setData(outcome.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd') ))
     window.location.href='#report'
  } 
   


  var nf = new Intl.NumberFormat();

  const tableRef = useRef(null);
  
var i=0;

var c=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

        <h1>تقرير الكمية المسلمة</h1>

   
     
   
      <br /> <br />
      <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />

    


<div className='container1'>
    <DownloadTableExcel 
                    filename="outcome"
                    sheet="المصروفات"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
  
<table id='report' class="table" ref={tableRef}>
<thead>
<tr>

<th scope="col">التاريخ</th>
<th scope="col">المستخدم</th>
<th scope="col">العدد</th>

<th scope="col">#</th>



  
   



          </tr>
    </thead>
<tbody>
{data.map((item,index)=>(



          <tr>
  <td className='hide' >{c += Number(item.amount) } </td>
    <td>{item.date}</td> 

    <td>{item.admin_name}</td> 
    <td> {item.amount} </td>
   <th scope="row">{i+=1}</th>
   </tr>
   
          
))}
</tbody>

<tfoot>

<tr>
<td colSpan={2}><b>{nf.format(Number(c))}</b></td>
<th colSpan={2} scope="row">اجمالي العدد المسلم</th>
</tr>

</tfoot>

</table>
</div>

</div>
 )}


  </div> 

  )
  }
  

export default Iceemprep;

