import React,{useEffect, useState} from 'react';
import axios from 'axios';

import Myincome from "./Myincome";
import {useAuthUser} from 'react-auth-kit'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

const Addincome = () => {

  
  const auth = useAuthUser();  

  useEffect( () => {

   
  
    allclient();
    
}, []); 




const handdle_change = (id) => {
  setSale({
    ...sale,
    ['client']:id
  }); 


}

const [client, setClient] = useState([]);

  const [sale, setSale] = useState({
    client: '',
    user: '',
    paid:'',
    note: '',
    receipt:'',
  });


  const onChangeValue = (e) => {
    setSale({
      ...sale,
      [e.target.name]:e.target.value
    });

    
  } 




  const allclient = async () => {
  

    try {
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/client.php?key=`+key+'&username='+username)
      .then(res => {
       
        setClient(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }


  const [date, setDate] = useState(new Date()); 
  const addproduct = async(event) => {
   
   
    try {
      event.preventDefault();
      event.persist();

      
      const username =  auth().username;
    const key = auth().key;
      var formData = new FormData();
      
      formData.append("client_id",sale.client); 
      formData.append("paid",sale.paid);
      formData.append("date",format(date, 'yyyy-MM-dd')); 
      formData.append("note",sale.note); 
      formData.append("receipt",sale.receipt); 
      formData.append("username",username); 
      formData.append("key",key); 
      
      axios.post(`https://diamond.future-swift.com/api/addincome.php`, formData)
      .then(res => {
        console.log(res.data);
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    

  };





return (


  <dev>
    
   
  <form className="insertForm" onSubmit={addproduct}>
    <h1> اضافة ايراد جديد </h1>

    
    

  <label htmlFor="_client">لحساب</label> 
  <br></br>

  
      
<DatalistInput
dir='rtl'
style={{color:'#000',}}
placeholder="العميل"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  client.map(option => (

  { id: option.value, value:option.label }
  
  ))

}
/>
  
<br /> <br />

    <label htmlFor="_paid">الوارد</label>
    <input
      type="number"
      id="_paid"
      name="paid"
      min={0}
      onChange={onChangeValue}
      placeholder="Enter paid"
      autoComplete="off"
      required
    />
    <br /> <br />

    <label htmlFor="_date">  التاريخ </label>
    <DatePicker  selected={date} onChange={(date) =>   
setDate(date)}
dateFormat="yyyy-MM-dd" 

required

/> 
<br /> <br />
<div dir='rtl' className="flex">
   <label htmlFor="_receipt"> السند</label>
  <input
  style={{width:'30%'}}
      type=""
      id="_receipt"
      name="receipt"
      min={0}
      onChange={onChangeValue}
      placeholder="Receipt no."
      autoComplete="off"
      required
    />
   
   <label htmlFor="_note"> ملاحظة</label>
  <textarea
  style={{width:'60%'}}
      type=""
      id="_note"
      name="note"
      min={0}
      onChange={onChangeValue}
      placeholder="Optional"
      autoComplete="off"
      required
    />

    </div>
    <br /> 
    <input type="submit" value="حفظ" />
  </form>

<Myincome/>

  </dev>
);
};

export default Addincome;




