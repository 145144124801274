import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
const Income = () => {
 
  const auth = useAuthUser();  
 
  
  useEffect( () => {
    
   
      allusers();
    window.scrollTo(0, 0);
   
    allincome();
  
    
 
  }, []); 

 
  const allusers = async () => {
    
  
    try {
  
      
      axios.get(`https://diamond.future-swift.com/api/user.php`)
      .then(res => {
       
        setUser(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }



  const handleSelect = (eventKey) =>{
    localStorage.setItem('user',eventKey);
    setIncome([]);
    
    allincome();
   
   
  } 
  const [user, setUser] = useState([]);
  const [income, setIncome] = useState([]);
  
const [loading, setLoading] = useState(false);

 
  const allincome = async () => {
    setLoading(true);
  
    try {
     
      var show = localStorage.getItem('user'); 
      
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/income.php?key=`+key+`&username=`+username+'&show='+show)
      .then(res => {
        
        setIncome(res.data.productlist.productdata);
       
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }


  
  
  


  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const incomeft = async (date,date1) => {
    
  
    try {
      var show = localStorage.getItem('user'); 
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/filterincome.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&show='+show)
      .then(res => {
        
        setIncome(res.data.productlist.productdata);
      
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setLoading(true);
    setIncome([]);
    incomeft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 





  var nf = new Intl.NumberFormat();
 


  const tableRef = useRef(null);
  
  
  var t_income=0;

  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
        <h1>الايرادات</h1>
    <h2>:عرض ايرادات</h2>
    <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
    <Nav.Item >
          <Nav.Link  eventKey="0" >الكل</Nav.Link>
        </Nav.Item>
    {user.map((user,index)=>( 
      
        <Nav.Item>
          <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
        </Nav.Item>
        
        
        ))}
       
      </Nav>

       <br />
    
      <br /> <br />
      <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />
  
    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="income"
                    sheet="الايرادات"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
<a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>

    <table dir='rtl' class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">#</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">لحساب</th>
    <th scope="col">الوارد</th>
    <th scope="col">المستخدم</th>
    <th scope="col">التاريخ</th>
   
    
  
        </tr>
        </thead>
  <tbody>
    {income.map((item,index)=>(
      

        <tr>
        <th scope="row">{i+=1}</th>	
        <td>{item.client_id}</td>
        <td>{item.client_name}</td>
        <td>{ nf.format(item.paid)} </td>
        <td>{item.admin_name}</td> 
        <td>{item.date}</td> 
       
      
   
      
        
        <td className='hide'>{t_income+=Number(item.paid) } </td>
       
        
       </tr>
     
              
  ))}
  </tbody>
  
 <tfoot id='tfoot'>
  
  <tr >
  <th  scope="row">اجمالي الايرادات</th>
  <td colSpan={9}><b>{ nf.format(Number(t_income))} YER</b></td>
  </tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Income;

