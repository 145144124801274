import React, {useEffect,useRef,useState} from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'



const Myclient = () => {
 
  const auth = useAuthUser();  
  useEffect( () => {
    

    
    window.scrollTo(0, 0);
    

    myclient();
  

 
    
 
  }, []); 
  
  
   

  const [loading, setLoading] = useState(false);
  
  const [isproduct, setproduct] = useState([]);
  
  const myclient = async () => {
    
  
    try {
      setLoading(true);
      const username =  auth().username;
    const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/myclient.php?key=`+key+`&username=`+username)
      .then(res => {
        
        setproduct(res.data.productlist.productdata);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  }

  const tableRef = useRef(null);

  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

    <h1>الحسابات او العملاء</h1>
    
      <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
    <div className='container1'>
    <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التعديل</th>
    <th scope="col">رقم التلفون</th>
    <th scope="col">اسم الحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
        </tr>
        </thead>
  <tbody>
    {isproduct.map((item,index)=>(
      
        <tr>
        <td><Link  to={`edit/${item.client_id}`} className="btn default-btn"> تعديل </Link></td> 
        <td>{item.phone}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	
       </tr>
     
              
  ))}
  </tbody>

  
  </table>
  </div>
 
  </div>
 )}  
    </div>
  )
  }
  

export default Myclient;

