import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";

import {useAuthUser} from 'react-auth-kit'



const Myemployee = () => {

  const auth = useAuthUser();  
  useEffect( () => {
   
      
    window.scrollTo(0, 0);
    

    allemployee();
  

 
    
 
  }, []); 
  

   

  const [loading, setLoading] = useState(false);
  
  const [isproduct, setproduct] = useState([]);
  
  const allemployee = async () => {
    
    setLoading(true);
    try {
     
      const username =  auth().username;
    const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/myemployee.php?key=`+key+`&username=`+username)
      .then(res => {
        
        setproduct(res.data.productlist.productdata);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  }



  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

    <h1>الحسابات او الموظفين</h1>
    
    
    <div className='container1'>
    <table class="table">
    <thead>
    <tr>
    <th scope="col">التعديل</th>
    <th scope="col">رقم التلفون</th>
    <th scope="col">اسم الحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
        </tr>
        </thead>
  <tbody>
    {isproduct.map((item,index)=>(
      
        <tr>
        <td><Link  to={`edit/${item.emp_id}`} className="btn default-btn"> تعديل </Link></td> 
        <td>{item.phone}</td> 
        <td>{item.emp_name}</td>
        <td>{item.emp_id}</td>
        <th scope="row">{i+=1}</th>	
       </tr>
     
              
  ))}
  </tbody>

  
  </table>
  </div>
     
  </div>
 )}
    </div>
  )
  }
  

export default Myemployee;

