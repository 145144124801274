import React, {useState} from 'react'
import axios from 'axios';


import {useAuthUser} from 'react-auth-kit'
import Myclient from "./Myclient";
const Addclient = () => {
  const auth = useAuthUser();  

  const [client, setClient] = useState({
    name: '',
    phone:'',
  });


  const onChangeValue = (e) => {
    setClient({
      ...client,
      [e.target.name]:e.target.value
    });
  
    
  } 
  
  
  const addclient = async(event) => {
     
     
    try {
      event.preventDefault();
      event.persist();
  
      const username =  auth().username;
      const key = auth().key;
    
    
      var formData = new FormData();
      formData.append("name",client.name); 
      formData.append("phone",client.phone); 
      formData.append("username",username); 
      formData.append("key",key); 

      axios.post(`https://diamond.future-swift.com/api/addclient.php`, formData)
      .then(res => {
        console.log(res.data);
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };
  
  return (
    
    <dev>
      
     
    <form className="insertForm" onSubmit={addclient}>
      <h1> اضافة حساب/عميل جديد </h1>
  
   
     
    
    
    
      <br /> <br />
    <label htmlFor="_name">أسم الحساب</label>
    <input
      type="text"
      id="_name"
      name="name"
      onChange={onChangeValue}
      placeholder="Enter name"
      autoComplete="off"
      required
    />

    <br /> <br />
    <label htmlFor="_phone">رقم الهاتف</label>
    <input
      type="phone"
      id="_phone"
      name="phone"
      onChange={onChangeValue}
      placeholder="Enter Phone"
      autoComplete="off"
      required
    />
   
    <br /> <br />
      <input type="submit" value="حفظ" />
    </form>
    
  <Myclient/>
    </dev>
  )
  }


export default Addclient;









