import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";

import {useAuthUser} from 'react-auth-kit'
const Mysales = () => {
  const auth = useAuthUser();  


useEffect( () => {


   
  
  window.scrollTo(0, 0);
  

  allproduct();

}, []); 



const [loading, setLoading] = useState(false);

const [isproduct, setproduct] = useState([]);

const allproduct = async () => {
  
  setLoading(true);
  try {
    const username =  auth().username;
    const key = auth().key;
    axios.get(`https://diamond.future-swift.com/api/mysales.php?key=`+key+`&username=`+username)
    .then(res => {
      
      setproduct(res.data.productlist.productdata);
    })
  } catch (error) { throw error;}    
  finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }
}


const deleteConfirm = (id) => {
  if (window.confirm(" هل أنت متأكد بأنك تريد حذف المبيع رقم"+id+" ؟ ")) {
    deleteUser(id);
  }
};
const deleteUser = async (id) => {
  try {

    var formData = new FormData();
    
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("sale_id",id); 

    axios.post(`https://diamond.future-swift.com/api/deletesale.php`,formData )
    .then(res => {
      setproduct([]);
      allproduct();
      return;
     })
  } catch (error) { throw error;}    
}



var i=0;
return (
  
  <div>
  


 
  {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

  <h1>مبيعاتي</h1>
  
  
  <div className='container1'>
  <table class="table">
  <thead>
  <tr>
  <th scope="col">التعديل</th>
  <th scope="col">الحذف</th>
  <th scope="col">ملاحظة</th>
  <th scope="col">التاريخ</th>
  <th scope="col">رقم السند</th>
  <th scope="col">المتبقي</th>
  <th scope="col">الوارد</th>
  <th scope="col">طريقة الدفع</th>
  <th scope="col">الأجمالي</th>
  <th scope="col">السعر</th> 
  <th scope="col">العدد</th>
  <th scope="col">الصنف</th>
  <th scope="col">اسم العميل</th>
  <th scope="col">رقم العميل</th>
  <th scope="col">#</th>
  
  
 
	
     
 
 

			</tr>
      </thead>
<tbody>
  {isproduct.map((item,index)=>(

    

			<tr>
      <td><Link  to={`edit/${item.sale_id}`} className="btn default-btn"> تعديل </Link></td> 
      <td><p onClick={() => deleteConfirm(item.sale_id)} className="btn default-btn"> حذف </p> </td> 
      <td>{item.note}</td> 
      <td>{item.date}</td> 
      <td>{item.receipt}</td> 
      <td>{Number(item.total)-Number(item.paid)} </td>
      <td>{item.paid} </td>
  
      <td>{Number(item.status) === 0 ? "اجل" 
        : "نقد"}</td>
      <td>{item.total} </td>
      <td>{item.price} </td>
      <td>{item.amount}</td>
      <td>{item.product}</td> 
      <td>{item.client_name}</td>
      <td>{item.client_id}</td>
     
      
      
         
     
      <th scope="row">{i+=1}</th>				
			</tr>

            
))}
</tbody>


</table>
</div>
   
   </div>
 )}
  </div>
)
}


export default Mysales;









