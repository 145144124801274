import { useEffect, useState } from "react";
import { Link, Navigate, } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import axios from 'axios';
import { useSignOut } from "react-auth-kit";
import {useAuthUser} from 'react-auth-kit'

const Header=()=>{
 
 const logout = useSignOut();

 const auth = useAuthUser();  
   

useEffect(()=>{

  
    user1();
},[])




const logOut =()=>{
  logout();
    localStorage.removeItem('key');
    localStorage.clear();
    
    Navigate('/');

}



const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);



const [userInfo, setuserInfo] = useState({});

const user1 = async () => {
 

  try {
    var username =  auth().username;
    var key = auth().key;
      
    axios.get(`https://diamond.future-swift.com/api/userinfo.php?key=`+key+`&username=`+username)
    .then(res => {
    
      console.log(res.data.userinfo.userdata);
      setuserInfo(res.data.userinfo.userdata[0]);
      
    })
   
  } catch (error) { throw error;}    
}
        
        return(

<div>

          <Navbar   fixed="top"  bg="light" expand="lg">
          <Container>
          <div className="imglink" >
          <label className="username"> <Link onClick={()=>setShow(false)} to='myprofile' style={{color:'#213d57',textDecoration:'none'}}> <img className="icon" src={'https://diamond.future-swift.com/api/profile/'+userInfo.picture} alt="" /><b>{auth().name}</b></Link> </label>
              </div>
              <Navbar.Brand className="title"><Link  to='/home' style={{color:'#213d57',textDecoration:'none'}} onClick={()=>setShow(false)}><b>Diamond</b></Link></Navbar.Brand>
       
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=>setShow(!show)} />
        <Navbar.Collapse in={show} id="basic-navbar-nav">
          <Nav >
            
          
                  
            <NavDropdown id="nav-dropdown-dark-example" title="الملف الشخصي" menuVariant="dark">
             <NavDropdown.Divider />
             <Link className="head_link" onClick={()=>setShow(!show)} to='myprofile' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > حسابي </Link>
              <NavDropdown.Divider />
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='sales' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > المبيعات </Link> 
               <Link className="head_link" onClick={()=>setShow(!show)}  to='income' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  >  صندوق الأيرادات </Link>
                <Link className="head_link" onClick={()=>setShow(!show)}  to='outcome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > صندوق المصروفات </Link> 
              <NavDropdown.Divider />
                <Link className="head_link" onClick={()=>setShow(!show)}  to='insert' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة بيع جديد </Link> 
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='addincome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة ايراد جديد </Link>  
              <NavDropdown.Divider />
                <Link className="head_link" onClick={()=>setShow(!show)}  to='addoutcome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة صرف جديد </Link>          
              <NavDropdown.Divider />
                <Link className="head_link" onClick={()=>setShow(!show)}  to='myclient' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > الحسابات/العملاء </Link> 
                <Link className="head_link" onClick={()=>setShow(!show)}  to='myemployee' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > الحسابات/الموظفين </Link> 
            </NavDropdown>



             <NavDropdown id="nav-dropdown-dark-example" title="المبيعات" menuVariant="dark">
             
                <Link className="head_link" onClick={()=>setShow(!show)}  to='mysale' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  >  مبيعاتي </Link>
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='insert' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة بيع جديد </Link> 
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='myincome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > ايراداتي </Link> 
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='addincome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة ايراد جديد </Link> 
              <NavDropdown.Divider />
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='myclient' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > الحسابات/العملاء </Link> 
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='addclient' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة حساب/عميل جديد </Link> 
            </NavDropdown>      
          
          
         

            <NavDropdown id="nav-dropdown-dark-example" title="المصروفات" menuVariant="dark">
              <Link className="head_link" onClick={()=>setShow(!show)}  to='myoutcome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > مصروفاتي</Link> 
                <Link className="head_link" onClick={()=>setShow(!show)}  to='addoutcome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة صرف جديد</Link> 
              <NavDropdown.Divider />
                <Link className="head_link" onClick={()=>setShow(!show)}  to='myemployee' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > الحسابات/الموظفين</Link> 
                <Link className="head_link" onClick={()=>setShow(!show)}  to='addemployee' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > اضافة حساب/موظف جديد</Link> 
            </NavDropdown>    
           
            

                 
            <NavDropdown id="nav-dropdown-dark-example" title="التقارير" menuVariant="dark">
                <Link className="head_link" onClick={()=>setShow(!show)}  to='sales' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > المبيعات</Link> 
              <NavDropdown.Divider />
                <Link className="head_link" onClick={()=>setShow(!show)}  to='income' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > صندوق الأيرادات</Link> 
                <Link className="head_link" onClick={()=>setShow(!show)}  to='outcome' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > صندوق المصروفات</Link> 
               <NavDropdown.Divider />
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='inrep' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > تقرير ايراد حساب/عميل محدد</Link> 
                 <Link className="head_link" onClick={()=>setShow(!show)}  to='outrep' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > تقرير صرف حساب/موظف محدد</Link> 
               <NavDropdown.Divider />
                <Link className="head_link" onClick={()=>setShow(!show)}  to='clients' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > تقرير لجميع الحسابات/العملاء</Link> 
                <Link className="head_link" onClick={()=>setShow(!show)}  to='client' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > تقرير لحساب/لعميل محدد</Link> 
          
              <NavDropdown.Divider />
       
            </NavDropdown>   
            
            
            <NavDropdown id="nav-dropdown-dark-example" title="المسلم من المصنع" menuVariant="dark">
            <NavDropdown.Divider />
              <Link className="head_link" onClick={()=>setShow(!show)}  to='iceemp' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  > ادارة الكمية المسلمة</Link> 
               <Link className="head_link" onClick={()=>setShow(!show)}  to='iceemprep' style={{color:'lightgray',textDecoration:'none',width:'96%',display:'flex',margin:'2%',padding:1}}  >تقارير الكمية المسلمة</Link> 
              <NavDropdown.Divider />
             
            </NavDropdown>      
            
            
            
            
            
             <Link  className="logout" onClick={logOut} ><b>LogOut</b></Link>
            </Nav>
            </Navbar.Collapse>
                
                
                
               
              
                
            </Container>
    </Navbar>

    </div>


        ); 
       
       
    }
    


export default Header;