import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Clientsreport = () => {
 
  const auth = useAuthUser();  

  
  useEffect( () => {
   
    
    allusers();
   

 
  
  }, []); 
  


 
  const allusers = async () => {
    
  setLoading(true)
    try {
  
      
      axios.get(`https://diamond.future-swift.com/api/user.php`)
      .then(res => {
        if(res.data.success === true){
          setUser(res.data.productlist.productdata);
        }
        allclients();
      
      })
    } catch (error) { throw error;}    
  
  }



  const handleSelect = (eventKey) =>{
    localStorage.setItem('user',eventKey);
    setClients([]);
    allclients();
   
  } 
  
  
  const [user, setUser] = useState([]);
  const [clients, setClients] = useState([]);
  
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const allclients = async () => {
    setLoading(true);
  
    try {
     
      var show = localStorage.getItem('user'); 

      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/clientsreport.php?key=`+key+`&username=`+username+'&show='+show)
      .then(res => {
        if(res.data.success === true){
          setClients(res.data.productlist.productdata);
          setData(res.data.productlist.productdata);
    
        }
     
      setLoading(false);
          window.location.href='#report'
      })
   
    } catch (error) { throw error;}    
   
  }
  

  
  const [status, setStatus] = useState('0');

  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 

  const onChangeValue = (e) => {
    console.log(e.target.value)
    setStatus(e.target.value)
    }

  
  
  const filt = () =>{
 
 if(status === '0'){
  setData(clients.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd') )) 
 }else if(status === '3'){
  setData(clients.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd')  && Number(item.total) === 0 )) 
 }
 
 else{
  setData(clients.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd') && Number(item.status) === Number(status-1) && Number(item.total) > 0 )) 
 }
  
 window.location.href='#report'
  } 
  




  const tableRef = useRef(null);
  var nf = new Intl.NumberFormat();

  var depit=0;
  var t_paid=0;
  var t_sale=0;
  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
    <h2>:تقرير ل</h2>
    <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
    <Nav.Item >
          <Nav.Link  eventKey="0" >الكل</Nav.Link>
        </Nav.Item>
    {user.map((user,index)=>( 
      
        <Nav.Item>
          <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
        </Nav.Item>
        
        
        ))}
       
      </Nav>

       <br />
     <div dir='rtl'>
   
      <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label>الحالة</label>
<select   onChange={onChangeValue}>
  <option value={0} selected >الكل</option>
  <option value={1}>الاجل</option>
  <option value={2}>النقد</option>
  <option value={3}>دفع لاجل</option>
  
</select>
<br /> <br />
  <button onClick={filt}>بحث</button>

  </div>



    <h1>تقرير تفصيلي للحسابات/للعملاء</h1>
    
    
  
    <div className='container1'>
    <DownloadTableExcel 
                    filename="allclient"
                    sheet="العملاء"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
      <a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>
    <table id='report' class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">ملاحظة</th>
    <th scope="col">التاريخ</th>
    <th scope="col">رقم السند</th>
    <th scope="col">المستخدم</th>
    <th scope="col">المتبقي</th>
    <th scope="col">الوارد</th>
   
    <th scope="col">طريقة الدفع</th>
    <th scope="col">الأجمالي</th>
    <th scope="col">السعر</th> 
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">اسم العميل</th>
    <th scope="col">رقم العميل</th>
    <th scope="col">#</th>
    
  
        </tr>
        </thead>
  <tbody>
    {data.map((item,index)=>(
      
      
  Number(item.total) === 0 ?  
        <tr>
        <td>{item.note}</td> 
        <td>{item.date}</td> 
        <td>{item.receipt}</td> 
        <td>{item.admin_name}</td> 
        <td></td>
        <td>{item.paid} </td>
    
        <td>دفع لاجل</td>
        <td colSpan={4} >دفع من مبلغ متبقي عليه</td>
        
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        <td className='hide'>{nf.format(t_sale+=Number(item.total) )} </td>
        <td className='hide'>{nf.format(t_paid+=Number(item.paid) )} </td>
       </tr>
      :
     <tr>
     <td>{item.note}</td> 
        <td>{item.date}</td> 
        <td>{item.receipt}</td> 
        <td>{item.admin_name}</td> 
        <td>{nf.format(Number(item.total)-Number(item.paid))} </td>
        <td>{nf.format(item.paid)} </td>
  
        <td>{Number(item.status) === 0 ? "اجل" 
          : "نقد"}</td>
        <td>{nf.format(item.total)} </td>
        <td>{nf.format(item.price)} </td>
        <td>{item.amount}</td>
        <td>{item.product}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	
        <td className='hide'>{t_sale+=Number(item.total) } </td>
        <td className='hide'>{t_paid+=Number(item.paid) } </td>
        <td className='hide'>{Number(item.status)===0 ? depit+= Number(item.total-item.paid) :''} </td>
     	</tr>
       	
     
      
       
              
  ))}
  </tbody>
 

<tfoot id='tfoot'>
<tr>
<th  colSpan={5} scope="row">اجمالي المبيعات</th>
<th colSpan={5}  scope="row">اجمالي الوارد</th>
<th colSpan={5} scope="row">اجمالي الاجل</th>
</tr>


<tr>

<td colSpan={5}><b>{nf.format(Number(t_sale))}</b></td>
<td colSpan={5}><b>{nf.format(Number(t_paid))}</b></td>
<td colSpan={5}><b>{nf.format(Number(depit))}</b></td>
</tr>
  
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Clientsreport ;









