import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";

import {useAuthUser} from 'react-auth-kit'



const Myoutcome = () => {

  const auth = useAuthUser();  
  useEffect( () => {
 
    window.scrollTo(0, 0);
    

    allproduct();
  

 
    
 
  }, []); 
  
  
   

  const [loading, setLoading] = useState(false);
  
  const [isproduct, setproduct] = useState([]);
  
  const allproduct = async () => {
    
    setLoading(true);
    try {
     
      const username =  auth().username;
    const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/myoutcome.php?key=`+key+`&username=`+username)
      .then(res => {
        
        setproduct(res.data.productlist.productdata);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  }



  const deleteConfirm = (id) => {
    if (window.confirm(" هل أنت متأكد بأنك تريد حذف الصرف رقم"+id+" ؟ ")) {
      deleteUser(id);
    }
  };
  const deleteUser = async (id) => {
    try {
  
      var formData = new FormData();
      
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
      formData.append("out_id",id); 
  
      axios.post(`https://diamond.future-swift.com/api/deleteoutcome.php`,formData )
      .then(res => {
        setproduct([]);
        allproduct();
        return;
       })
    } catch (error) { throw error;}    
  }

  
  
  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

    <h1>مصروفاتي</h1>
    
    
    <div className='container1'>
    <table class="table">
    <thead>
    <tr>
    <th scope="col">التعديل</th>
    <th scope="col">الحذف</th>
    <th scope="col">التاريخ</th>
    <th scope="col">الصرف لاجل</th>
    <th scope="col">المبلغ</th>
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {isproduct.map((item,index)=>(
      
        <tr>
        <td><Link  to={`edit/${item.out_id}`} className="btn default-btn"> تعديل </Link></td> 
      <td><p onClick={() => deleteConfirm(item.out_id)} className="btn default-btn"> حذف </p> </td> 
        <td>{item.date}</td> 
        <td>{item.reason}</td> 
        <td>{item.total}</td> 
        <td>{item.emp_name}</td>
        <td>{item.emp_id}</td>
       
        <th scope="row">{i+=1}</th>	
        
       </tr>
     
              
  ))}
  </tbody>

  
  </table>
  </div>
     
  </div>
 )}
    </div>
  )
  }
  

export default Myoutcome;

