import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { Table } from 'react-bootstrap';
const Clientrep = () => {
 
  const auth = useAuthUser();  
 
  
  useEffect( () => {
    
      allclient();
      
  }, []); 
  


  const [client, setClient] = useState([]);

  const [clientinf, setClientinf] = useState([]);
  
  const [data, setData] = useState([]);


  const [total, setTotal] = useState(0);

  const allclient = async () => {
    setLoading(true);

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/allclient.php?key=`+key+'&username='+username)
      .then(res => {
       
        if(res.data.success === true){
          setClient(res.data.productlist.productdata);
        }
    
    
        setLoading(false);
      })
    } catch (error) { throw error;}    
  
  }


  const handdle_change = (id) => {

    setClientinf([]);
    setTotal(0);

  clientinfo(id);
  setSelected(1)


   window.location.href='#report'
  }
  

  const [selected, setSelected] = useState(0);






  
    const [loading, setLoading] = useState(false);

  const clientinfo = async (id) => {

   setLoading(true)
    try {
     
 
     
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/clientrep.php?key=`+key+`&username=`+username+'&id='+id)
      .then(res => {
        if(res.data.success === true){

          setClientinf(res.data.productlist.productdata);
        setData(res.data.productlist.productdata);
        setTotal(Number(res.data.depit))

        }
      
     
        setLoading(false)
         window.location.href='#report'
      }
    
    )
   
    } catch (error) { throw error;}    

  }



  
  const [status, setStatus] = useState('0');

  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 



  
  const onChangeValue = (e) => {
    console.log(e.target.value)
    setStatus(e.target.value)
    }

  
  const filt = () =>{
 
    if(status === '0'){
     setData(clientinf.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd') )) 
    }else if(status === '3'){
     setData(clientinf.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd')  && Number(item.total) === 0 )) 
    }
    
    else{
     setData(clientinf.filter((item) => item.date >= format(startDate, 'yyyy-MM-dd') && item.date <= format(enddate, 'yyyy-MM-dd') && Number(item.status) === Number(status-1) && Number(item.total) > 0 )) 
    }
     setSelected(0)
    window.location.href='#report'
     } 
 

 




  var nf = new Intl.NumberFormat();


  const tableRef = useRef(null);
  var depit=0;
  var t_paid=0;
  var t_sale=0;
  var i=0;
  return (
    
    <div>
    
    <h1>تقرير لحساب/لعميل</h1>
    <br /> <br />
    <br /> <br />




  <br></br>


   
    <br /> <br />
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
   
        <div dir='rtl'>
  

        <label  dir='rtl' htmlFor="_client">الحساب</label> 


  
      
<DatalistInput

style={{color:'#000',}}
placeholder="العميل"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  client.map(option => (

  { id: option.value, value:`${option.label} ${option.status > 0 ? " (غير مسدد) " : " (مسدد) "  }` }
  
  ))

}
/>

    <br /> <br />
    <label  dir='rtl' htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label  dir='rtl' htmlFor="_date">الى</label>
    <DatePicker  selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   

    <br /> <br />

<label>الحالة</label>
<select   onChange={onChangeValue}>
<option value={0} selected >الكل</option>
<option value={1}>الاجل</option>
<option value={2}>النقد</option>
<option value={3}>دفع لاجل</option>

</select>
<br /> <br />
  <button onClick={filt}>بحث</button>

<br /> <br />

</div> 
    
    <div className='container1' id='report'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
<a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>
   
    <table class="table" ref={tableRef} >
  

    <caption style={{captionSide:'top'}}>
    <div className='flex' style={{width:'100%',zIndex:1,placeItems:'center',marginLeft:'0.5%',marginRight:'0.5%' ,backgroundColor:'ButtonHighlight'}}  dir='rtl'>




<div  style={{width:'33%'}}>
<p style={{color:'black',fontSize:'20px',textAlign:'center'}}>كشف حساب </p>

<p style={{color:'black',fontSize:'20px',textAlign:'center'}}>ل{clientinf.length > 0 ? clientinf[0].client_name :''}</p>


</div>

<div className='flex' style={{width:'33%'}}>
<img  style={{margin:'auto'}} src='https://diamond.future-swift.com/diamond-logo.png' />
</div> 

<div  style={{width:'33%',}}>
<p style={{color:'black',fontSize:'15px',textAlign:'center'}}>{format(startDate, 'yyyy-MM-dd') === format(enddate, 'yyyy-MM-dd') ? 

selected === 1 ?
'كل الفترة'
:
`    ${format(startDate, 'yyyy-MM-dd')}`

:

selected === 1 ?
'كل الفترة'
:
`   ${format(startDate, 'yyyy-MM-dd')} / ${format(enddate, 'yyyy-MM-dd')}`
}</p>
</div>

</div>
<hr></hr>
  </caption>
<thead>
    <tr>
    <th scope="col">ملاحظة</th>
    <th scope="col">التاريخ</th>
    <th scope="col">رقم السند</th>
    <th scope="col">المستخدم</th>
    <th scope="col">المتبقي</th>
    <th scope="col">الوارد</th>
   
    <th scope="col">طريقة الدفع</th>
    <th scope="col">الأجمالي</th>
    <th scope="col">السعر</th> 
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">اسم العميل</th>
    <th scope="col">رقم العميل</th>
    <th scope="col">#</th>
    
  
        </tr>
        </thead>
        <tr ><th colSpan={15} ><hr></hr></th></tr>


  <tbody>
    {data.map((item,index)=>(
      
      
  Number(item.total) === 0 ?  
        <tr>
        <td>{item.note}</td> 
        <td>{item.date}</td> 
        <td>{item.receipt}</td> 
        <td>{item.admin_name}</td> 
        <td></td>

        <td>{nf.format(item.paid)} </td>
      
        <td>دفع لاجل</td>
        <td colSpan={4} >دفع من مبلغ متبقي عليه</td>
        
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        <td className='hide'>{nf.format(t_sale+=Number(item.total) )} </td>
        <td className='hide'>{nf.format(t_paid+=Number(item.paid) )} </td>
       </tr>
      :
     <tr>
     <td>{item.note}</td> 
        <td>{item.date}</td> 
        <td>{item.receipt}</td> 
        <td>{item.admin_name}</td> 
        <td>{nf.format(Number(item.total)-Number(item.paid))} </td>
        <td>{nf.format(item.paid)} </td>
       
        <td>{Number(item.status) === 0 ? "اجل" 
          : "نقد"}</td>
        <td>{nf.format(item.total)} </td>
        <td>{nf.format(item.price)} </td>
        <td>{item.amount}</td>
        <td>{item.product}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	

        <td className='hide'>{t_sale+=Number(item.total) } </td>
        <td className='hide'>{t_paid+=Number(item.paid) } </td>
        <td className='hide'>{Number(item.status)===0 ? depit+= Number(item.total-item.paid) :''} </td>
     	</tr>
       	
     
      
       
              
  ))}
  </tbody>


  {Number(status) > 0 ? 


    <tfoot id='tfoot'>


<tr>
<th colSpan={10} scope="row"> <b>{nf.format(Number(total))}</b></th>
<th colSpan={5} scope="row">المبلغ المتبقي عليه </th>
</tr>


  
</tfoot>


  :
    <tfoot id='tfoot'>
<tr colSpan={15}>
<th  colSpan={15} scope="row"><hr></hr> </th>
</tr>


<tr>
<th  colSpan={3} scope="row">اجمالي المبيعات</th>
<th colSpan={3}  scope="row">اجمالي المسلم</th>
<th colSpan={3} scope="row">اجمالي الاجل</th>

{ format(startDate, 'yyyy-MM-dd') === format(enddate, 'yyyy-MM-dd') ? 

selected === 1 ?

<th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه</th>
:
 <th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه في تاريخ {format(startDate, 'yyyy-MM-dd')}</th>

:

selected === 1 ?
<th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه</th>
:
<th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه من {format(startDate, 'yyyy-MM-dd')} / {format(enddate, 'yyyy-MM-dd')}</th>
}
<th colSpan={3} scope="row">المبلغ المتبقي عليه ككل</th>
</tr>


<tr>

<td colSpan={3}><b>{nf.format(Number(t_sale))}</b></td>
<td colSpan={3}><b>{nf.format(Number(t_paid))}</b></td>
<td colSpan={3}><b>{nf.format(depit)}</b></td>
<td colSpan={3}><b>{nf.format(Number(t_sale)-Number(t_paid))}</b></td>

<td colSpan={3}><b>{nf.format(Number(total))}</b></td>
</tr>
  
</tfoot>
}
 
  
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Clientrep ;









