import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
import DatalistInput from 'react-datalist-input';

const Inrep = () => {
 
  const auth = useAuthUser();  
  
  useEffect( () => {
    
   
  
      allclient();
      
    window.scrollTo(0, 0);

 
 
  }, []); 
  

  const [client, setClient] = useState([]);

  const [clientinf, setClientinf] = useState([]);
  




  const allclient = async () => {
  

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/allclient.php?key=`+key+'&username='+username)
      .then(res => {
       
        setClient(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }




  const [clientid, setClientid] = useState();



    const handdle_change = (id) => {

      setClientinf([]);
   
      setClientid(id);
      clientinfo(id);
  
  
     window.location.href='#report'
    }
   
  
    const [loading, setLoading] = useState(false);
  const clientinfo = async (id) => {
    
    setLoading(true);
    try {
     
     
    
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/inrep.php?key=`+key+`&username=`+username+'&id='+id)
      .then(res => {
        
      
       

        if(res.data.success === true){
          setClientinf(res.data.productlist.productdata);
        }
    
    
        setLoading(false);
      })
   
    } catch (error) { throw error;}    
   
    
  }



  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const clientft = async (date,date1) => {
    
    setLoading(true);

    try {
      
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/filterin.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&client_id='+clientid)
      .then(res => {
        
      
        if(res.data.success === true){
          setClientinf(res.data.productlist.productdata);
        }
    
    
        setLoading(false);
      })
   
    } catch (error) { throw error;}    
   
  }
  
  
  
  const filt = () =>{
    setClientinf([]);

    clientft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 

 


  var nf = new Intl.NumberFormat();


  const tableRef = useRef(null);
  
  var t_income=0;
  var i=0;
  return (
    
    <div>
    
    <h1>تقرير ايراد لحساب/لعميل</h1>
    

    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
   
   
   
        <br /> <br />
    <DatalistInput

style={{color:'#000',}}
placeholder="العميل"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  client.map(option => (

  { id: option.value, value:`${option.label} ${option.status > 0 ? " (غير مسدد) " : " (مسدد) "  }` }
  
  ))

}
/>

  
<br /> <br />
    <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />
    

   
    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
      <a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>

      <table id='report' dir='rtl' class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">#</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">لحساب</th>
    <th scope="col">الوارد</th>
    <th scope="col">المستخدم</th>
    <th scope="col">التاريخ</th>
   
    
  
        </tr>
        </thead>
  <tbody>
    {clientinf.map((item,index)=>(
      

      <tr>
        <th scope="row">{i+=1}</th>	
        <td>{item.client_id}</td>
        <td>{item.client_name}</td>
        <td>{ nf.format(item.paid)} </td>
        <td>{item.admin_name}</td> 
        <td>{item.date}</td> 
       
      
   
      
        
        <td className='hide'>{t_income+=Number(item.paid) } </td>
       
        
       </tr>
     
              
  ))}
  </tbody>
  
 <tfoot id='tfoot'>
  
  <tr>
  <th  scope="row">اجمالي الايرادات</th>
  <td colSpan={9}><b>{ nf.format(Number(t_income))} YER</b></td>
  </tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Inrep ;









