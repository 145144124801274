import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
const Outcome = () => {
 
  const auth = useAuthUser();  


  
  useEffect( () => {
    
     
    window.scrollTo(0, 0);
    
 
    alloutcome();
 
    
  
  }, []); 
  
 


  

  const [outcome, setOutcome] = useState([]);
  
const [loading, setLoading] = useState(false);

  const alloutcome = async () => {
    
    setLoading(true);
    try {
     
      var show = 0; 
    
      const username =  auth().username;
    const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/outcome.php?key=`+key+`&username=`+username+'&show='+show)
      .then(res => {

        setOutcome([]);
        setOutcome(res.data.productlist.productdata);
       
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  

  
  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const outcomeft = async (date,date1) => {
    
    try {
      var show = localStorage.getItem('user'); 
      const username =  auth().username;
    const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/filteroutcome.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&show='+show)
      .then(res => {
        setOutcome([]);
     
        setOutcome(res.data.productlist.productdata);
      
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setLoading(true);
    setOutcome([]);
   
    outcomeft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
  
  } 
   


  var nf = new Intl.NumberFormat();

  const tableRef = useRef(null);
  var total=0;
  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

        <h1>المصروفات</h1>
  
    
      <br /> <br />
      <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />

    
    
    <div className='container1'>
   
    <DownloadTableExcel 
                    filename="outcome"
                    sheet="المصروفات"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />

<a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>

    <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">الصندوق</th>
    <th scope="col">الصرف لاجل</th>
    <th scope="col">المبلغ</th>
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">العملية رقم</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {outcome.map((item,index)=>(
      

        <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td>
        <td>{item.reason} </td> 
        <td>{nf.format(item.total)} </td>
        <td>{item.emp_name}</td>
        <td>{item.emp_id}</td>
        <td>{item.out_id}</td>
        <th scope="row">{i+=1}</th>	
        <td className='hide'>{total+=Number(item.total) } </td>
       </tr>
     
              
  ))}
  </tbody>
  
<tfoot id='tfoot'>
  
  <tr>
  <th  scope="row">اجمالي المصروفات</th>
  <td colSpan={6}><b>{nf.format(total)} YER</b></td>
  </tr>
  </tfoot>
  </table>
  </div>

</div>
 )}


  </div> 

  )
  }
  

export default Outcome;

