import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';


import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Sales = () => {
 


  const auth = useAuthUser();  


  
  useEffect( () => {
   



     allusers();
   
    
    allsales();
  
 
 
  }, []); 
  
  
 
  const allusers = async () => {
    
  
    try {
  
      
      axios.get(`https://diamond.future-swift.com/api/user.php`)
      .then(res => {
       
        setUser(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }


  const tableRef = useRef(null);
 
  const handleSelect = (eventKey) =>{
    localStorage.setItem('user',eventKey);
    setproduct([]);
    allsales();
  } 
  
  const handleSelect1 = (eventKey) =>{
    localStorage.setItem('week',eventKey); 
    setproduct([]);
    allsales();
  } 
  
  const [user, setUser] = useState([]);
  const [isproduct, setproduct] = useState([]);
  
  
const [loading, setLoading] = useState(false);

  const allsales = async () => {
    
    setLoading(true);
    try {
     
      var show = localStorage.getItem('user'); 
    
      var username =  auth().username;
    var key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/sales.php?key=`+key+`&username=`+username+'&show='+show)
      .then(res => {
       
        if(res.data.success === true){
          setproduct([]);
          setproduct(res.data.productlist.productdata);
        }
        setLoading(false);
          window.location.href='#report'
       
      })
   
    } catch (error) { throw error;}    
   
  }


  

  const [enddate, setendDate] = useState(new Date()); 
  const [startDate, setStartDate] = useState(new Date()); 
  
  const allsales1 = async (date,date1) => {
    setLoading(true)
  
    try {

      var show = localStorage.getItem('user'); 
      
      var username =  auth().username;
    var key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/filtersale.php?key=`+key+`&username=`+username+'&show='+show+'&date='+date+'&date1='+date1)
      .then(res => {
        if(res.data.success === true){
          setproduct([]);
          setproduct(res.data.productlist.productdata);
        }
        setLoading(false);
          window.location.href='#report'
       
      })
   
    } catch (error) { throw error;}    
   
  }
  
  
  
  const filt = () =>{
    setLoading(true);
   
    
    
    allsales1(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 


  var nf = new Intl.NumberFormat();
  var depit=0;
  var t_paid=0;
  var t_sale=0;
  var count=0;
  var i=0;
  return (
    
    <div>
    
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

        <h1>المبيعات</h1>

    <h2>:عرض عمليات</h2>
    <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
    <Nav.Item >
          <Nav.Link  eventKey="0" >الكل</Nav.Link>
        </Nav.Item>
    {user.map((user,index)=>( 
      
        <Nav.Item>
          <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
        </Nav.Item>
        
        
        ))}
       
      </Nav>

       <br />
    
      
      <br /> <br />
    <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

    <br /> <br />

    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="sales"
                    sheet="sales"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
      <a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>
    <table id='report' class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">المتبقي</th>
    <th scope="col">الوارد</th>

    <th scope="col">طريقة الدفع</th>
    <th scope="col">الأجمالي</th>
    <th scope="col">السعر</th> 
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">اسم العميل</th>
    <th scope="col">رقم العميل</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {isproduct.map((item,index)=>(
      
      
     <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td>{ nf.format(Number(item.total)-Number(item.paid))} </td>
        <td>{ nf.format(item.paid)} </td>
  
        <td>{Number(item.status) === 0 ? "اجل" 
          : "نقد"}</td>
        <td>{ nf.format(item.total)} </td>
        <td>{ nf.format(item.price)} </td>
        <td>{item.amount}</td>
        <td>{item.product}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	

        <td className='hide'>{count+=Number(item.amount) } </td>
        <td className='hide'>{t_sale+=Number(item.total) } </td>
        <td className='hide'>{t_paid+=Number(item.paid) } </td>
        <td className='hide'>{Number(item.status)===0 ? depit+= Number(item.total-item.paid) :''} </td>
     	</tr>
       	
     
      
       
              
  ))}
  </tbody>
 <tfoot id='tfoot'>
<tr colSpan={12}>
<th  colSpan={12} scope="row"><hr></hr> </th>
</tr>


<tr>
<th colSpan={3} scope="row">اجمالي المبيعات</th>
<th colSpan={3}  scope="row">اجمالي النقد</th>
<th colSpan={3} scope="row">اجمالي الاجل</th>
<th colSpan={3} scope="row">اجمالي العدد</th>
</tr>


<tr>

<td colSpan={3}><b>{ nf.format(Number(t_sale))} YER</b></td>
<td colSpan={3}><b>{ nf.format(Number(t_paid))} YER</b></td>
<td colSpan={3}><b>{ nf.format(Number(depit))} YER</b></td>
<td colSpan={3}><b>{ nf.format(Number(count))} KG</b></td>
</tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Sales;









