import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';



import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Outrep = () => {
 


  const auth = useAuthUser();  

  
  useEffect( () => {
 


      allclient();
      
   

    
 
  }); 
  
 

  const [client, setClient] = useState([]);

  const [clientinf, setClientinf] = useState([]);
  



  const allclient = async () => {
  

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/allemployee.php?key=`+key+'&username='+username)
      .then(res => {
       
        setClient(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }




  const [clientid, setClientid] = useState();

  const onChangeValue = (e) => {
    setClientinf([]);
    
    setClientid(e.target.value);
    clientinfo(e.target.value);

    }


   
  
    const [loading, setLoading] = useState(false);
  const clientinfo = async (id) => {
    
    setLoading(true);
    try {
     
     
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/employeerep.php?key=`+key+`&username=`+username+'&id='+id+'&week='+week)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
       

      
      })
   
    } catch (error) { throw error;}    
    finally{
    
      setTimeout(() => {
        setLoading(false);
      }, 400);
   
    }  
  }



  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const clientft = async (date,date1) => {
    
    setLoading(true);

    try {
      
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/filteremployee.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&client_id='+clientid)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
       
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setClientinf([]);
    
    clientft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 

 





  var nf = new Intl.NumberFormat();


  const tableRef = useRef(null);
  
  var total = 0;
  var i=0;
  return (
    
    <div>
   
    <h1>تقرير صرف لحساب/لموظف</h1>
    <br /> <br />
    <br /> <br />

      <label htmlFor="_client">الحساب</label> 
  <br></br>
    <select id='_client' name='client'  onChange={onChangeValue} required>
    <option value='' selected> Choose... </option>
        {client.map(option => (
          
          <option key={option.value} value={option.value}>

            {option.label }

          </option>
        ))}
      </select>
    
   
    <br /> <br />
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
    <h3>:لمدة </h3>
    
    <br /> <br />


    <br /> <br />
    <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />
   
    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />

<a href='#tfoot' style={{position:'absolute',right:10,textDecoration:'none',color:'gray'}}>
الأجمالي
<svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
 style={{width:20}}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
    ></path>
  </svg>
</a>

     <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">الصندوق</th>
    <th scope="col">الصرف لاجل</th>
    <th scope="col">المبلغ</th>
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">العملية رقم</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {clientinf.map((item,index)=>(
      

        <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td>
        <td>{item.reason} </td> 
        <td>{nf.format(item.total)} </td>
        <td>{item.emp_name}</td>
        <td>{item.emp_id}</td>
        <td>{item.out_id}</td>
        <th scope="row">{i+=1}</th>	
        <td className='hide'>{total+=Number(item.total) } </td>
       </tr>
     
              
  ))}
  </tbody>
  
<tfoot id='tfoot'>
  
  <tr>
  <th  scope="row">اجمالي المصروفات</th>
  <td colSpan={6}><b>{nf.format(total)} YER</b></td>
  </tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Outrep ;









