import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';



import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Conc = () => {
   

  const auth = useAuthUser();  


 
  
  useEffect( () => {
    
    

    clientinfo();
    clientinfo1();
   
  }, []); 
  









  const [clientinf, setClientinf] = useState([]);
  
  const [clientinf1, setClientinf1] = useState([]);


 



   
  
    const [loading, setLoading] = useState(false);
  const clientinfo = async () => {
    
    setLoading(true);
    try {
     
     
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/playoffs3.php?key=`+key+`&username=`+username)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
      
        

      
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }




  const clientinfo1 = async () => {
  
    try {
     
     
      
      const username =  auth().username;
    const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/playoffs4.php?key=`+key+`&username=`+username)
      .then(res => {
        
        setClientinf1(res.data.productlist.productdata);

      })
   
    } catch (error) { throw error;}    
    
  }







  



  var nf = new Intl.NumberFormat();


  const tableRef = useRef(null);
  const tableRef1 = useRef(null);
  
  var c=0;
  var b=0;
  var i=0;
  return (
    
    <div>
    
    <h1>خلاصة الفترة</h1>
    
   
    <br /> <br />
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

   
     
  
   

   <div className='container2'>
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
    <table class="table" ref={tableRef}>

    <thead>

    <tr>
 
  
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">الصرف لاجل</th>
    <th scope="col">المبلغ</th>
 
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
    
  
        </tr>
        </thead>
  <tbody>
    {clientinf1.map((item,index)=>(

        <tr>
      <td className='hide' >{b += Number(item.total) } </td>
        <td>{item.date1}</td> 
        <td>{item.admin_name1}</td>
        <td>{item.reason} </td> 
        <td>{nf.format(item.total)} </td>
       
        <td>{item.emp_name}</td>
        <td>{item.emp_id}</td>
        <th scope="row">{i+=1}</th>	
        
        
       </tr>
     
              
  ))}
</tbody>
  
 <tfoot>
 
 <tr>
  <td colSpan={7}><b>{ nf.format(Number(b))}</b></td>
  <th  scope="row">اجمالي المصروفات</th>
  </tr>

  
  </tfoot>
  </table>
  </div>
     
    <div className='container1'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef1.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef1.current}
      />
    <table  class="table" ref={tableRef1}>

    <thead>

    <tr>
 
    
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">الاجمالي</th>
    <th scope="col">السعر</th>
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {clientinf.map((item,index)=>(
      

        <tr>
        <td className='hide' >{c += Number(item.total) } </td>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td>{ nf.format(item.total)} </td>
        <td>{ nf.format(item.price)} </td>
        <td>{ nf.format(item.amount)} </td>
        <td>{item.product} </td>
       
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        
       </tr>
     
              
  ))}
</tbody>
  
 <tfoot>
 <tr>
 <td colSpan={8}><b>{ nf.format(Number(c))}</b></td>
  <th  scope="row">اجمالي المبيعات</th>
  </tr>
  

  
  </tfoot>
  </table>
  </div>
  
  </div>
  <hr></hr>
{ <div style={{color: Number(c)-Number(b) > 0 ? "green" :  "red"}}>
  
<label htmlFor="">الحالة : {Number(c)-Number(b) > 0 ? "رابح" :"غير رابح"}</label>  
   <br></br>
    <label htmlFor="">اجمالي المبيعات = {nf.format(Number(c))}</label>  
   <br></br>
    <label htmlFor="">اجمالي المصروفات = {nf.format(Number(b))}</label>  
    <br /> <br />
    <label htmlFor="">الأرباح = {nf.format(Number(c)-Number(b))}</label>  
<hr></hr>
    </div>
}
  </div>
 )}

    </div>
  )
  }
  

export default Conc ;









